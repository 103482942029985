import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { rolesAndDepertmentService } from '../../../services/services';

export const getDropDowns = createAsyncThunk(
	'dependent/department',
	async (credentials, thunkAPI) => {
		try {
			const response = await rolesAndDepertmentService();
			if (response.data.success == true) {
				return response.data;
			} else {
				throw new Error(response.data.message);
			}
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

const dropDownsSlice = createSlice({
	name: 'dependent/department/roles',
	initialState: {
		role: [],
		department: [],
		dependent: [],
		isLoading: false,
		error: null,
	},

	extraReducers: (builder) => {
		builder
			.addCase(getDropDowns.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(getDropDowns.fulfilled, (state, action) => {
				state.isLoading = false;
				state.role = action?.payload.data.role_data;
				state.department = action?.payload?.data?.department_data;
				state.dependent = action?.payload?.data?.dependent_data;
				state.error = null;
			})
			.addCase(getDropDowns.rejected, (state, action) => {
				state.isLoading = false;
				state.role = [];
				state.department = [];
				state.dependent = [];
				state.error = action.payload || 'An error occurred';
			});
	},
});

export const Roles = createSelector(
	state => state?.dropDown?.role,
	roles => roles.map(item => ({ value: item.id, label: item.role_name }))
  );
  
  export const Departments = createSelector(
	state => state?.dropDown?.department,
	departments => departments.map(item => ({ value: item.id, text: item.department_name }))
  );
  
  export const Dependents = createSelector(
	state => state?.dropDown?.dependent,
	dependents => dependents.map(item => ({ value: item.id, text: item.relation_type }))
  );

  export const isLoadingData = createSelector(state => state?.dropDown?.isLoading);
  export const errors = createSelector(state => state?.dropDown?.error);
  

export default dropDownsSlice.reducer;
