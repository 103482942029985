import React from 'react';
import { DotLoader } from 'react-spinners';
import './loader.css'; // Import your custom CSS for styling
// eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-expressions
const Loader = ({ isLoading }) => {
	return (
		<div className={`loader-overlay ${isLoading ? 'visible' : 'hidden'}`}>
			<div className='loader-container'>
				<DotLoader color='#36D7B7' loading={isLoading} size={150} />
			</div>
		</div>
	);
};

export default Loader;
