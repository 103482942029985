import React, { lazy, useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { auth, publicPages } from '../../routes/contentRoutes';
import AuthContext from '../../contexts/authContext';
import { useSelector } from 'react-redux';
import Loader from '../../components/common/DotLoader';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
interface RouteData {
	path: string;
	element: React.ReactNode;
}
const ContentRoutes = () => {
	const { userData } = useContext(AuthContext);
	const [contents, setContent] = useState<RouteData[] | null>(null);
	const [isLoading, setLoading] = useState(true);
	const currentEmployee = useSelector((state: any) => state.employee.currentEmployee);
	const navigate = useNavigate();

	useEffect(() => {
		if (localStorage.getItem('token')) {
			if (!currentEmployee || !currentEmployee.data) {
				setLoading(true); // Set loading state to true
				return;
			}
			const menu = currentEmployee.data;

			const filteredAuth = auth.filter((route) => {
				const { path } = route;

				return Object.values(menu).some((submenu) => {
					return Object.values(submenu).some((item: any, i: any) => {
						return Object.values(item)?.some((subItem: any) => {
							return subItem.path === path;
						});
					});
				});
			});

			setContent([...filteredAuth, ...publicPages]);
			setLoading(false); // Set loading state to false after setting content
		} else {
			setContent(publicPages);
			setLoading(false); // Set loading state to false
			navigate('/auth-pages/login');
		}
	}, [currentEmployee, navigate]);

	if (isLoading) {
		return <Loader isLoading={true} />;
	}

	if (!contents) {
		return null;
	}

	return (
		<Routes>
			{contents.map((page) => (
				<Route key={page.path} {...page} />
			))}
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
