import React from 'react';

import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import { store } from './redux/store/store';
import { Provider } from 'react-redux'
import './i18n';


const children = (
	<Provider store={store}>
	<AuthContextProvider>
		<ThemeContextProvider>
			<Router>
				<React.StrictMode>
					<App />
				</React.StrictMode>
			</Router>
		</ThemeContextProvider>
	</AuthContextProvider>
	</Provider>
);

const container = document.getElementById('root');

createRoot(container as Element).render(children); 

reportWebVitals();
