export const summaryPageTopMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
	
};

export const demoPagesMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
};

export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'CS Management System',
	},
	pharmacy: {
		id: 'updatePharmacyProfile',
		text: 'Pharmacies',
		path: 'pharmacies/updatePharmacyProfile',
		icon: 'MedicalServices',
		hide: true,
	},
	employee: {
		id: 'employees',
		text: 'Employees',
		path: 'employee',
		icon: 'People',
		hide: false,

		subMenu: {
			addEmployee: {
				id: 'addEmployee ',
				text: 'Add Employee [a-h]',
				path: '/employee/add',
				icon: 'Add',
				hide: false,
			},
			listEmployees: {
				id: 'listEmployees',
				text: 'List Employees [a-h]',
				path: '/employee/list',
				icon: 'FormatListNumbered',
				hide: false,
			},
			editEmployee: {
				id: 'editemployee',
				text: 'Edit Employee',
				path: '/employee/edit',
				hide: true,
			},
			employeeDetail: {
				id: 'employeeDetail',
				text: 'Employee Employee',
				path: '/employee/detail',
				hide: true,
			},
		},
	},

	leaves: {
		id: 'leaves',
		text: 'Leaves',
		path: 'leaves',
		icon: 'BeachAccess',
		hide: false,

		subMenu: {
			requestaddLeaves: {
				id: 'requestAddLeaves',
				text: 'Apply Leave [e-h]',
				path: '/leaves/requestAddLeaves',
				icon: 'Add',
				hide: false,
			},
			listRequestleaves: {
				id: 'listRequestleaves',
				text: 'Listing [a-h]',
				path: '/leaves/listRequestedLeaves',
				icon: 'FormatListNumbered',
				hide: false,
			},
			listleavesBalance: {
				id: 'listleavesBalance',
				text: 'Leave Balance [a-h]',
				path: '/leaves/balance',
				icon: 'FormatListNumbered',
				hide: false,
			},
			editleaves: {
				id: 'editLeaves',
				text: 'Edit Leaves',
				path: '/leaves/editleaves',
				icon: 'EditLocation',
				hide: true,
			},
		},
	},
	// projects: {
	// 	id: 'projects123',
	// 	text: 'Projects',
	// 	path: 'Projects',
	// 	icon: 'Task',
	// 	hide: true,

	// 	subMenu: {
	// 		requestAddProjects: {
	// 			id: 'requestAddProjects',
	// 			text: 'Add Project [a-h]',
	// 			path: 'projects/requestAddProjects',
	// 			icon: 'Add',
	// 			hide: false,
	// 		},
	// 		listRequestProjects: {
	// 			id: 'listRequestCategories',
	// 			text: 'Listing [a-hs]',
	// 			path: 'categories/listRequestedCategories',
	// 			icon: 'FormatListNumbered',
	// 			hide: false,
	// 		},
	// 		listEmployeeProjects: {
	// 			id: 'listProjects',
	// 			text: 'Listing [e]',
	// 			path: 'projects/list',
	// 			icon: 'FormatListNumbered',
	// 			hide: false,
	// 		},
	// 		editProjects: {
	// 			id: 'editCategory',
	// 			text: 'Edit Category',
	// 			path: 'categories/editCategory',
	// 			icon: 'EditLocation',
	// 			hide: true,
	// 		},
	// 	},
	// },
	attendance: {
		id: 'attendane',
		text: 'Attendance',
		path: 'attendance',
		icon: 'EventNote',
		hide: false,

		subMenu: {
			markAttendance: {
				id: 'markAttendance',
				text: 'Mark Attendance',
				path: '/attendance/mark',
				icon: 'MarkChatRead',
				hide: false,
			},
			listAttendance: {
				id: 'listAttendances',
				text: 'List Attendance',
				path: '/attendance/list',
				icon: 'FormatListNumbered',
				hide: false,
			},
			EmplistAttendance: {
				id: 'EmplistAttendance',
				text: 'My Attendance [e]',
				path: '/attendance/list',
				icon: 'FormatListNumbered',
				hide: true,
			},
			attendanceDetail: {
				id: 'attendanceDetail',
				text: 'Attendance Detail',
				path: '/employee/attendance/detail',
				icon: 'Details',
				hide: false,
			},
		},
	},
	// reporting: {
	// 	id: 'reporting',
	// 	text: 'Reporting',
	// 	path: 'reporting',
	// 	icon: 'Report',
	// 	hide: true,

	// 	subMenu: {
	// 		listReporting: {
	// 			id: 'listReporting',
	// 			text: 'Expense Report',
	// 			path: '/reporting/listReporting',
	// 			icon: 'FormatListNumbered',
	// 			hide: true,
	// 		},
	// 		orderDetail: {
	// 			id: 'reportingDetail',
	// 			text: 'Reporting Detail',
	// 			path: '/reporting/reportingDetail',
	// 			icon: 'Details',
	// 			hide: true,
	// 		},
	// 	},
	// },
	// 	permissions: {
	// 		id: 'permissions',
	// 		text: 'Permissions',
	// 		path: 'permissions',
	// 		icon: 'Accessibility',
	// 		hide: true,

	// 		subMenu: {
	// 	Permissions: {
	// 		id: 'permission',
	// 		text: 'Perissions [a]',
	// 		path: 'customers/listCustomers',
	// 		icon: 'People',
	// 	},
	// }},
	roles: {
		id: 'roles',
		text: 'Roles',
		path: 'roles',
		icon: 'AccountBox',
		hide: false,

		subMenu: {
			addRole: {
				id: 'role',
				text: 'Add Role',
				path: '/roles/add',
				icon: 'Add',
			},
			listRole: {
				id: 'permission',
				text: 'List Roles',
				path: '/roles/list',
				icon: 'List',
			},
			editRole: {
				id: 'editRole',
				text: 'Edit Role',
				path: '/roles/edit',
				icon: 'Edit',
			},
		},
	},
};

export const SuperAdminMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'CS Management System',
	},
	pharmacy: {
		id: 'updatePharmacyProfile',
		text: 'Pharmacies',
		path: 'pharmacies/updatePharmacyProfile',
		icon: 'MedicalServices',
		hide: true,
	},
	employee: {
		id: 'employees',
		text: 'Employees',
		path: 'employee',
		icon: 'People',
		hide: false,

		subMenu: {
			addEmployee: {
				id: 'addEmployee',
				text: 'Add Employee',
				path: 'employes/addEmployee',
				icon: 'PersonAdd',
				hide: false,
			},
			listEmployees: {
				id: 'listEmployees',
				text: 'List Employees',
				path: 'employee/listEmployees',
				icon: 'FormatListNumbered',
				hide: false,
			},
			editEmployee: {
				id: 'editemployee',
				text: 'Edit Employee',
				path: '/employee/editEmployee/',
				hide: true,
			},
			employeeDetail: {
				id: 'editemployee',
				text: 'Employee Employee',
				path: '/employee/detail/',
				hide: true,
			},
		},
	},

	leaves: {
		id: 'leaves',
		text: 'Leaves',
		path: 'leaves',
		icon: 'BeachAccess',
		hide: false,

		subMenu: {
			requestaddLeaves: {
				id: 'requestAddLeaves',
				text: 'Apply Leave',
				path: 'leaves/requestAddLeaves',
				icon: 'Add',
				hide: false,
			},
			listRequestleaves: {
				id: 'listRequestleaves',
				text: 'Listing',
				path: 'leaves/listRequestedLeaves',
				icon: 'FormatListNumbered',
				hide: false,
			},
			listleavesBalance: {
				id: 'listRequestleaves',
				text: 'Leave Balance',
				path: 'leaves/Balance',
				icon: 'FormatListNumbered',
				hide: false,
			},
			editleaves: {
				id: 'editLeaves',
				text: 'Edit Leaves',
				path: 'leaves/editleaves',
				icon: 'EditLocation',
				hide: true,
			},
		},
	},
	projects: {
		id: 'projects',
		text: 'Projects',
		path: 'Projects',
		icon: 'Category',
		hide: false,

		subMenu: {
			requestAddProjects: {
				id: 'requestAddProjects',
				text: 'Add Project',
				path: 'projects/requestAddProjects',
				icon: 'Add',
				hide: false,
			},
			listRequestProjects: {
				id: 'listRequestCategories',
				text: 'Listing',
				path: 'categories/listRequestedCategories',
				icon: 'FormatListNumbered',
				hide: false,
			},
			editProjects: {
				id: 'editCategory',
				text: 'Edit Category',
				path: 'categories/editCategory',
				icon: 'EditLocation',
				hide: true,
			},
		},
	},
	attendance: {
		id: 'orders',
		text: 'Attendance',
		path: 'orders',
		icon: 'EventNote',
		hide: false,

		subMenu: {
			EmplistAttendance: {
				id: 'EmplistAttendance',
				text: 'List Attendance',
				path: '/attendance/listAttendance',
				icon: 'FormatListNumbered',
				hide: false,
			},
			attendanceDetail: {
				id: 'attendanceDetail',
				text: 'Attandance Detail',
				path: '/attendance/attendanceDetail',
				icon: 'Details',
				hide: false,
			},
		},
	},
	reporting: {
		id: 'reporting',
		text: 'Reporting',
		path: 'reporting',
		icon: 'Report',
		hide: false,

		subMenu: {
			listReporting: {
				id: 'listReporting',
				text: 'Expense Report',
				path: '/reporting/listReporting',
				icon: 'FormatListNumbered',
				hide: false,
			},
			orderDetail: {
				id: 'reportingDetail',
				text: 'Reporting Detail',
				path: '/reporting/reportingDetail',
				icon: 'Details',
				hide: true,
			},
		},
	},
	permissions: {
		id: 'permissions',
		text: 'Permissions',
		path: 'permissions',
		icon: 'EventNote',
		hide: false,

		subMenu: {
			Permissions: {
				id: 'permission',
				text: 'Perissions',
				path: 'customers/listCustomers',
				icon: 'People',
			},
		},
	},
};

export const superAdminPageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'CS Management',
	},

	pharmacies: {
		id: 'pharmacies',
		text: 'Pharmacies',
		path: 'pharmacies',
		icon: 'MedicalServices',
		subMenu: {
			listPendingPharmacies: {
				id: 'listPendingPharmacies',
				text: 'List Pending',
				path: 'pharmacies/listPendingPharmacies',
				icon: 'Pending',
				hide: false,
			},
			listApprovedPharmacies: {
				id: 'listApprovedPharmacies',
				text: 'List Approved',
				path: 'pharmacies/listApprovedPharmacies',
				icon: 'AddTask',
				hide: false,
			},
		},
	},

	categories: {
		id: 'categories',
		text: 'Categories',
		path: 'categories',
		icon: 'Category',
		subMenu: {
			listRequestCategories: {
				id: 'listRequestCategories',
				text: 'List Requested',
				path: 'categories/listRequestedCategories',
				icon: 'RequestPage',
				hide: false,
			},
			approveRequest: {
				id: 'approveRequest',
				text: 'Approve Request',
				path: 'categories/approveRequest',
				icon: 'AddTask',
				hide: false,
			},
		},
	},
	customers: {
		id: 'customers',
		text: 'Customers',
		path: 'customers/listCustomers',
		icon: 'People',
	},
	profile: {
		id: 'updateProfile',
		text: 'Profile',
		path: 'updateProfile',
		icon: 'Person',
		hide: true,
	},

	// charts: {
	// 	id: 'charts',
	// 	text: 'Charts',
	// 	path: 'charts',
	// 	icon: 'AreaChart',
	// 	notification: 'success',
	// 		subMenu: {
	// 			chartsRadialBar: {
	// 				id: 'chartsRadialBar',
	// 				text: 'Radial Bar',
	// 				path: 'charts/radial-bar',
	// 				icon: 'DonutLarge',
	// 			},
	// 		},

	// }
};

export const EmployeePagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'CS Management',
	},

	leaves: {
		id: 'leaves',
		text: 'My Leaves',
		path: 'leaves',
		icon: 'BeachAccess',
		hide: false,

		subMenu: {
			requestaddLeaves: {
				id: 'requestAddLeaves',
				text: 'Apply Leave',
				path: 'leaves/requestAddLeaves',
				icon: 'PersonOff',
				hide: false,
			},
			listRequestleaves: {
				id: 'listRequestleaves',
				text: 'Listing',
				path: 'leaves/listRequestedLeaves',
				icon: 'FormatListNumbered',
				hide: false,
			},
			listleavesBalance: {
				id: 'listRequestleaves',
				text: 'Leave Balance',
				path: 'leaves/Balance',
				icon: 'FormatListNumbered',
				hide: true,
			},
			editleaves: {
				id: 'editLeaves',
				text: 'Edit Leaves',
				path: 'leaves/editleaves',
				icon: 'EditLocation',
				hide: true,
			},
		},
	},
	projects: {
		id: 'projects',
		text: 'Projects',
		path: 'Projects',
		icon: 'Category',
		hide: false,

		subMenu: {
			requestAddProjects: {
				id: 'requestAddProjects',
				text: 'Add Project',
				path: 'projects/requestAddProjects',
				icon: 'Add',
				hide: true,
			},
			listEmployeeProjects: {
				id: 'listProjects',
				text: 'Listing',
				path: 'projects/list',
				icon: 'FormatListNumbered',
				hide: false,
			},
			editProjects: {
				id: 'editCategory',
				text: 'Edit Category',
				path: 'categories/editCategory',
				icon: 'EditLocation',
				hide: true,
			},
		},
	},
	attendance: {
		id: 'orders',
		text: 'Attendance',
		path: 'orders',
		icon: 'Checklist',
		hide: false,

		subMenu: {
			listAttendance: {
				id: 'listAttendance',
				text: 'List Attendance',
				path: '/attendance/listAttendance',
				icon: 'FormatListNumbered',
				hide: true,
			},
			EmplistAttendance: {
				id: 'EmplistAttendance',
				text: 'List Attendance',
				path: '/attendance/listAttendance',
				icon: 'FormatListNumbered',
				hide: false,
			},
			attendanceDetail: {
				id: 'attendanceDetail',
				text: 'Attandance Detail',
				path: '/attendance/attendanceDetail',
				icon: 'Details',
				hide: true,
			},
		},
	},
	reporting: {
		id: 'reporting',
		text: 'Reporting',
		path: 'reporting',
		icon: 'Report',
		hide: true,

		subMenu: {
			listReporting: {
				id: 'listReporting',
				text: 'Expense Report',
				path: '/reporting/listReporting',
				icon: 'FormatListNumbered',
				hide: true,
			},
			orderDetail: {
				id: 'reportingDetail',
				text: 'Reporting Detail',
				path: '/reporting/reportingDetail',
				icon: 'Details',
				hide: true,
			},
		},
	},
	profile: {
		id: 'updateEmployeProfile',
		text: 'Profile',
		path: 'updateProfile',
		icon: 'Person',
		hide: true,
	},
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
