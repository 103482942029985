import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';

export interface IAuthContextProps {
	user: string;
	setUser?(...args: unknown[]): unknown;
	userData: Partial<IUserProps>;
	setUserData?(...args: unknown[]): void;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(
		localStorage.getItem('medsouk_admin_panel_authUsername') || '',
	);

	let userDataJSONString = localStorage.getItem('medsouk_admin_panel_userData');
	let userDataJSON;

	if (userDataJSONString == null) {
		userDataJSON = {};
	} else {
		userDataJSON = JSON.parse(userDataJSONString);
	}
	const [userData, setUserData] = useState<Partial<IUserProps>>(userDataJSON);

	useEffect(() => {
		localStorage.setItem('medsouk_admin_panel_authUsername', user);
		localStorage.setItem('medsouk_admin_panel_userData', JSON.stringify(userData));
	}, [user, userData]);

	useEffect(() => {
		// if (user !== '') {
		// 	setUserData(getUserDataWithUsername(user));
		// } else {
		// 	setUserData({});
		// }
		if (user === '') setUserData({});
	}, [user]);

	const value = useMemo(
		() => ({
			user,
			setUser,
			userData,
			setUserData,
		}),
		[user, userData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
