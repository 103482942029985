import { configureStore } from '@reduxjs/toolkit';
import authReducer from './../slices/authSlice/authSlice';
import dropDownsReducer from './../slices/rolesAndDepartmentSlice/roleAndDepSlice';
import employeeReducer from './../slices/emloyeeSlice/employeeSlice';
import permissions from './../slices/permissionsSlice/permissionSlice';
import roleReduer from './../slices/roleSlice/roleSlice';
import attendanceReducer from './../slices/attendanceSlice/attendanceSlice';
import leavesReducer from './../slices/leaveSlice/slice';

export const store = configureStore({
	reducer: {
		auth: authReducer,
		dropDown: dropDownsReducer,
		employee: employeeReducer,
		permissions: permissions,
		role: roleReduer,
		attendance: attendanceReducer,
		leaves: leavesReducer,
	},
});
