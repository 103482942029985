import React, { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../../redux/slices/authSlice/authSlice';
import Spinner from '../../../components/bootstrap/Spinner';
import Input from '../../../components/bootstrap/forms/Input';

const Login: FC = () => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const userData = useSelector((state: any) => state.auth.userData);
	const [loginError, setLoginError] = useState(null);
	const isLoading = useSelector((state: any) => state.auth.loading);
	const error = useSelector((state: any) => state.auth.error);
	const [toastMessage, setToastMessage] = useState('');

	const validate = (values: { loginUsername: string; loginPassword: string }) => {
		const errors: {
			loginUsername?: string;
			loginPassword?: string;
		} = {};
		if (!values.loginUsername) {
			errors.loginUsername = 'Email is required';
		} else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(values.loginUsername)) {
			errors.loginUsername = 'Invalid email format';
		}
		if (!values.loginPassword) {
			errors.loginPassword = 'Password is required';
		}
		return errors;
	};

	const formik = useFormik({
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate,
		onSubmit: async (values) => {
			const payload = {
				office_email: values.loginUsername,
				password: values.loginPassword,
			};
			//@ts-ignore
			dispatch(loginUser(payload));
		},
	});
	useEffect(() => {
		if (userData?.success) {
			setToastMessage(userData.message);
			navigate('/', { state: userData.message });
		} else if (userData?.success === false) {
			setLoginError(userData?.message);
		}
	}, [userData]);

	useEffect(() => {
		const user = localStorage.getItem('user');
		const token = localStorage.getItem('token');

		if (user && token) {
			localStorage.removeItem('hasToastBeenShown');
			navigate('/', { state: 'Welcome back! You are already logged in.' });
		}
	}, []);

	return (
		<PageWrapper isProtected={false} title='Login' className={classNames({ 'bg-light': true })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</Link>
								</div>
								<form className='row g-4' onSubmit={formik.handleSubmit}>
									<div className='col-12'>
										<FormGroup
											id='loginUsername'
											isFloating
											label='Your email '>
											<Input
												autoComplete='username'
												value={formik.values.loginUsername}
												isTouched={formik.touched.loginUsername}
												invalidFeedback={formik.errors.loginUsername}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup id='loginPassword' isFloating label='Password'>
											<Input
												type='password'
												autoComplete='current-password'
												value={formik.values.loginPassword}
												isTouched={formik.touched.loginPassword}
												invalidFeedback={
													formik.errors.loginPassword || error
												}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
										{loginError ? (
											<small className='ms-2 text-danger'>{loginError}</small>
										) : (
											''
										)}
									</div>
									<div className='col-12'>
										<Button
											color='warning'
											className='w-100 py-3'
											type='submit'>
											{isLoading && <Spinner isSmall inButton isGrow />}
											Login
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': true,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': true,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
