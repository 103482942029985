import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logInService } from '../../../services/services';

export const loginUser = createAsyncThunk('auth/login', async (credentials, thunkAPI) => {
	try {
		const response = await logInService(credentials);
		return response.data;
	} catch (error) {
		console.log(error);
		return thunkAPI.rejectWithValue(error.message || 'An error occurred');
	}
});

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
		userData: null,
		loading: false,
		error: null,
	},
	reducers: {
		logoutUser: (state) => {
			state.user = null;
			state.loading = false;
			state.error = null;
			state.userData = null;
			localStorage.removeItem('hasToastBeenShown');
			localStorage.removeItem('user');
			localStorage.removeItem('token');
		},
		resetUserData: (state) => {
			state.userData = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginUser.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(loginUser.fulfilled, (state, action) => {
				state.loading = false;
				state.user = action.payload.success ? action.payload?.data?.employee : null;
				state.userData = action.payload;
				state.error = null;
				if (action.payload.success) {
					// Fixed: Changed ternary operator to if statement
					localStorage.setItem('user', JSON.stringify(action?.payload?.data?.employee));
					localStorage.setItem('token', action?.payload?.data?.token);
				}
			})
			.addCase(loginUser.rejected, (state, action) => {
				state.loading = false;
				state.user = null;
				state.error = action.payload || 'An error occurred';
			});
	},
});

export const { logoutUser } = authSlice.actions;
export default authSlice.reducer;
