import React, { useContext } from 'react';
import AuthContext from '../contexts/authContext';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../layout/SubHeader/SubHeader';

const AccountStatusTopBar = () => {
	const { userData } = useContext(AuthContext);
	if (userData.pharmacy_status != 'P') return <></>;
	return (
		<>
			<SubHeader className='review-account'>
				<SubHeaderLeft>
					<div>
						<h4>
							Your account is in review. This will take 24 hours, for contact email us
							at <a href='mailto:support@medsouk.com'>support@medsouk.com</a>
						</h4>
					</div>
				</SubHeaderLeft>
			</SubHeader>
		</>
	);
};

export default AccountStatusTopBar;
