import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	addRoleService,
	applyLeaveService,
	getEmployeeLeaveService,
	getRequestedLeaveService,
	inactiveRoleService,
	updateRequestedLeaveService,
	updateRoleService,
} from '../../../services/services';

export const addRole = createAsyncThunk('addRole', async (credentials, thunkAPI) => {
	try {
		const response = await addRoleService(credentials);
		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const getRequestedLeaves = createAsyncThunk(
	'getRequestedLeaves',
	async (credentials, thunkAPI) => {
		try {
			const response = await getRequestedLeaveService(credentials);
			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);
export const getLeaves = createAsyncThunk('getEmployeeLeaves', async (credentials, thunkAPI) => {
	try {
		const { id } = credentials;
		const response = await getEmployeeLeaveService(id);

		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const applyLeaves = createAsyncThunk('applyLeaves', async (credentials, thunkAPI) => {
	try {
		const response = await applyLeaveService(credentials);

		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const updateLeave = createAsyncThunk('updateLeave', async (credentials, thunkAPI) => {
	try {
		const { id, ...reset } = credentials;

		const response = await updateRequestedLeaveService(id, reset);

		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const updateRoles = createAsyncThunk('updateRoles', async (credentials, thunkAPI) => {
	try {
		const { id, ...reset } = credentials;

		const response = await updateRoleService(id, reset);

		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const inactiveRoles = createAsyncThunk('inactiveRoles', async (credentials, thunkAPI) => {
	try {
		const { id, ...reset } = credentials;
		console.log('redux, ', id, reset);
		const response = await inactiveRoleService(id, reset);

		return response?.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
const leaveSlice = createSlice({
	name: 'leaveSlice',
	initialState: {
		leaves: null,
		isLoading: false,
		error: null,

		requestedLeaves: null,

		isSubmitting: false,
		addingRoleError: null,
		roles: null,

		update: null,
		isUpdating: false,
		updateError: null,
		inAtiveRole: null,
		isInactive: false,
		updateLeave: null,
		applyLeave: null,
		isApply: false,
		applyError: null,
	},
	reducers: {
		resetLeaves: (state) => {
			state.leaves = null;
			state.isSubmitting = false;
			state.addingRoleError = null;
			state.isLoading = false;
			state.error = null;
			state.update = null;
			state.isUpdating = null;
			state.updateError = null;
		},
		resetRequestedData: (state) => {
			state.applyLeave = null;
			state.isLoading = false;
			state.applyError = null;
		},
		resetAction: (state) => {
			state.updateLeave = null;
			state.isLoading = false;
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(addRole.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(addRole.fulfilled, (state, action) => {
				state.isLoading = false;
				state.leaves = action.payload;
				state.error = null;
			})
			.addCase(addRole.rejected, (state, action) => {
				state.isLoading = false;
				state.leaves = null;
				state.error = action.payload;
			});
		builder
			.addCase(updateRoles.pending, (state) => {
				state.isUpdating = true;
				state.updateError = null;
			})
			.addCase(updateRoles.fulfilled, (state, action) => {
				state.isUpdating = false;
				state.update = action.payload;
				state.updateError = null;
			})
			.addCase(updateRoles.rejected, (state, action) => {
				state.isUpdating = false;
				state.update = null;
				state.updateError = action.payload;
			});
		builder
			.addCase(getLeaves.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(getLeaves.fulfilled, (state, action) => {
				state.isLoading = false;
				state.leaves = action.payload;
				state.error = null;
			})
			.addCase(getLeaves.rejected, (state, action) => {
				state.isLoading = false;
				state.leaves = null;
				state.error = action.payload;
			});
		builder
			.addCase(getRequestedLeaves.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(getRequestedLeaves.fulfilled, (state, action) => {
				state.isLoading = false;
				state.requestedLeaves = action.payload;
				state.error = null;
			})
			.addCase(getRequestedLeaves.rejected, (state, action) => {
				state.isLoading = false;
				state.requestedLeaves = null;
				state.error = action.payload;
			});

		builder
			.addCase(applyLeaves.pending, (state) => {
				state.isLoading = true;
				state.applyError = null;
			})
			.addCase(applyLeaves.fulfilled, (state, action) => {
				state.isLoading = false;
				state.applyLeave = action.payload;
				state.applyError = null;
			})
			.addCase(applyLeaves.rejected, (state, action) => {
				state.isLoading = false;
				state.applyLeave = null;
				state.applyError = action.payload;
			});

		builder
			.addCase(updateLeave.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(updateLeave.fulfilled, (state, action) => {
				state.isLoading = false;
				state.updateLeave = action.payload;
				state.error = null;
			})
			.addCase(updateLeave.rejected, (state, action) => {
				state.isLoading = false;
				state.updateLeave = null;
				state.error = action.payload;
			});

		builder
			.addCase(inactiveRoles.pending, (state) => {
				state.isInactive = true;
				state.error = null;
			})
			.addCase(inactiveRoles.fulfilled, (state, action) => {
				state.isInactive = false;
				state.inAtiveRole = action.payload;
				state.error = null;
			})
			.addCase(inactiveRoles.rejected, (state, action) => {
				state.isInactive = false;
				state.inAtiveRole = null;
				state.error = action.payload;
			});
	},
});

export const { resetLeaves, resetRequestedData, resetAction } = leaveSlice.actions;
export default leaveSlice.reducer;
