import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentEmployee } from '../../../redux/slices/emloyeeSlice/employeeSlice';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import Brand from '../../../layout/Brand/Brand';
import User from '../../../layout/User/User';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import ThemeContext from '../../../contexts/themeContext';
import AuthContext from '../../../contexts/authContext';
import { dashboardPagesMenu } from '../../../menu';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { userData } = useContext(AuthContext);
	const currentEmployee = useSelector((state: any) => state.employee.currentEmployee);
	const dispatch = useDispatch();

	const [isSuperAdmin, setIsSuperAdmin] = useState(userData.is_super_admin === 'Y');
	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const [pages, setPages] = useState();

	useEffect(() => {
		//@ts-ignore
		dispatch(getCurrentEmployee());
	}, []);

	useEffect(() => {
		if (currentEmployee && currentEmployee.data) {
			const navigations = currentEmployee.data;

			setPages(navigations);
		} else {
			//@ts-ignore
			setPages({});
		}
	}, [currentEmployee]);

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />
				<NavigationLine />
				{!isSuperAdmin && (
					<>
						<Navigation
							//@ts-ignore
							menu={{ ...pages }}
							id='aside-menu'
						/>
						<NavigationLine />
					</>
				)}
				{/* {isSuperAdmin && (
				<>
					<Navigation menu={superAdminPageLayoutTypesPagesMenu} id='aside-menu' />
					<NavigationLine />
				</>
			)} */}
				{/* {asideStatus && doc && <div className='p-4'>Documentation</div>} */}
			</AsideBody>
			<AsideFoot>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
