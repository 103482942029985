import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	addEmployeeService,
	deleteDependentService,
	deleteEmployeeService,
	getAllEmployeeService,
	getEmployeeDetailService,
	updateEmployeeService,
	removeEmployeeImageService,
	removeEmployeeDocumentService,
	getCurrentEmployeeService,
} from '../../../services/services';

export const addEmployee = createAsyncThunk('add/employee', async (credentials, thunkAPI) => {
	try {
		const response = await addEmployeeService(credentials);
		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
export const getAllEmployee = createAsyncThunk('get/employees', async (credentials, thunkAPI) => {
	try {
		const response = await getAllEmployeeService();

		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const getEmployeeDetail = createAsyncThunk('get/detail', async (credentials, thunkAPI) => {
	try {
		const response = await getEmployeeDetailService(credentials);

		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
export const updateEmployee = createAsyncThunk('update/user', async (credentials, thunkAPI) => {
	try {
		const { id, formData } = credentials;

		const response = await updateEmployeeService(id, formData);

		return response?.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const deleteEmployeeImage = createAsyncThunk(
	'delete/image',
	async (credentials, thunkAPI) => {
		try {
			const { id } = credentials;

			const response = await removeEmployeeImageService(id);

			return response?.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const deleteEmployeeDocument = createAsyncThunk(
	'delete/document',
	async (credentials, thunkAPI) => {
		try {
			const { id } = credentials;

			const response = await removeEmployeeDocumentService(id);

			return response?.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const deleteEmployee = createAsyncThunk('delete/employee', async (credentials, thunkAPI) => {
	try {
		const response = await deleteEmployeeService(credentials);

		if (response.data.success === true) {
			return response.data;
		} else {
			throw new Error(response.data.message);
		}
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
export const deleteDependent = createAsyncThunk(
	'delete/employee/dependent',
	async (credentials, thunkAPI) => {
		try {
			const response = await deleteDependentService(credentials);

			if (response.data.success === true) {
				return response.data;
			} else {
				throw new Error(response.data.message);
			}
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const getCurrentEmployee = createAsyncThunk(
	'employee/current-employee',
	async (credentials, thunkAPI) => {
		try {
			const response = await getCurrentEmployeeService(credentials);

			if (response.data.success === true) {
				return response.data;
			} else {
				throw new Error(response.data.message);
			}
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

const employeeSlice = createSlice({
	name: 'employeeSlice',
	initialState: {
		currentEmployee:null,
		isCurrentEmployeeLoading:false,
		isCurrentEmployeeError:false,
		data: null,
		allEmployees: [],
		deleteDocument: null,
		isLoading: false,
		error: null,
		isDeleting: false,
		isDependentDeleting: false,
		deleteData: null,
		deleteImage: null,
		deletingError: null,
		employeeDetail: null,
		employeeDetailError: null,
		employeeDetailLoading: false,
		updateUser: null,
		isUpdate: false,
		updateError: null,
		dependent: null,
		deleteError: null,
	},
	reducers: {
		resetEmployeeDetail: (state) => {
			state.employeeDetail = null;
			state.employeeDetailLoading = false;
			state.employeeDetailError = null;
		},
		resetUpdate: (state) => {
			state.updateUser = null;
			state.updateError = null;
		},
		resetDependent: (state) => {
			state.dependent = null;
			state.isDeleting = false;
			state.error = null;
		},
		resetAdd: (state) => {
			state.data = null;
			state.error = null;
			state.isLoading = false;
		},
		resetDelete: (state) => {
			state.deleteData = null;
			state.deleteError = null;
			state.deleteDocument = null;
		},
		resetImageDelete: (state) => {
			state.deleteImage = null;
			state.deleteError = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(addEmployee.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(addEmployee.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = action.payload;
				state.error = null;
			})
			.addCase(addEmployee.rejected, (state, action) => {
				state.isLoading = false;
				state.data = null;
				state.error = action.payload || 'An error occurred';
			});

		builder
			.addCase(getAllEmployee.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(getAllEmployee.fulfilled, (state, action) => {
				state.allEmployees = action.payload.data;
				state.error = null;
				state.isLoading = false;
			})
			.addCase(getAllEmployee.rejected, (state, action) => {
				state.isLoading = false;
				state.data = null;
				state.error = action.payload || 'An error occurred';
			});
		builder
			.addCase(getEmployeeDetail.pending, (state) => {
				state.employeeDetailLoading = true;
				state.employeeDetailError = null;
			})
			.addCase(getEmployeeDetail.fulfilled, (state, action) => {
				state.employeeDetail = action.payload;
				state.employeeDetailLoading = false;
				state.employeeDetailError = null;
			})
			.addCase(getEmployeeDetail.rejected, (state, action) => {
				state.employeeDetailLoading = false;
				state.employeeDetail = null;
				state.employeeDetailError = action.payload || 'An error occurred';
			});
		builder
			.addCase(updateEmployee.pending, (state) => {
				state.isUpdate = true;
				state.updateError = null;
				state.updateUser = null;
			})
			.addCase(updateEmployee.fulfilled, (state, action) => {
				state.updateUser = action.payload;
				state.updateError = null;
				state.isUpdate = false;
			})
			.addCase(updateEmployee.rejected, (state, action) => {
				state.updateUser = null;
				state.isUpdate = false;
				state.updateError = action.payload.message || 'An error occurred';
			});

		builder
			.addCase(deleteEmployee.pending, (state) => {
				state.isDeleting = true;
				state.deletingError = null;
			})
			.addCase(deleteEmployee.fulfilled, (state, action) => {
				state.deleteData = action.payload;
				state.deletingError = null;
				state.isDeleting = false;
			})
			.addCase(deleteEmployee.rejected, (state, action) => {
				state.isDeleting = false;
				state.deleteData = null;
				state.deletingError = action.payload || 'An error occurred';
			});

		builder
			.addCase(deleteEmployeeImage.pending, (state) => {
				state.isLoading = true;
				state.deletingError = null;
			})
			.addCase(deleteEmployeeImage.fulfilled, (state, action) => {
				state.deleteImage = action.payload;
				state.deletingError = null;
				state.isLoading = false;
			})
			.addCase(deleteEmployeeImage.rejected, (state, action) => {
				state.isLoading = false;
				state.deleteImage = null;
				state.deletingError = action.payload || 'An error occurred';
			});

		builder
			.addCase(deleteEmployeeDocument.pending, (state) => {
				state.isLoading = true;
				state.deletingError = null;
			})
			.addCase(deleteEmployeeDocument.fulfilled, (state, action) => {
				state.deleteDocument = action.payload;
				state.deletingError = null;
				state.isLoading = false;
			})
			.addCase(deleteEmployeeDocument.rejected, (state, action) => {
				state.isLoading = false;
				state.deleteDocument = null;
				state.deletingError = action.payload || 'An error occurred';
			});

		builder
			.addCase(deleteDependent.pending, (state) => {
				state.isDependentDeleting = true;
				state.deleteError = null;
			})
			.addCase(deleteDependent.fulfilled, (state, action) => {
				state.deleteError = null;
				state.dependent = action.payload;

				state.isDependentDeleting = false;
			})
			.addCase(deleteDependent.rejected, (state, action) => {
				state.isDependentDeleting = false;
				state.dependent = null;
				state.deleteError = action.payload || 'An error occurred';
			});

			builder
			.addCase(getCurrentEmployee.pending, (state) => {
				state.isCurrentEmployeeLoading = true;
				state.isCurrentEmployeeError = null;
			})
			.addCase(getCurrentEmployee.fulfilled, (state, action) => {
				state.isCurrentEmployeeError = null;
				state.currentEmployee = action.payload;

				state.isCurrentEmployeeLoading= false;
			})
			.addCase(getCurrentEmployee.rejected, (state, action) => {
				state.isCurrentEmployeeLoading = false;
				state.currentEmployee = null;
				state.isCurrentEmployeeError = action.payload || 'An error occurred';
			});
	},
});
export const {
	resetEmployeeDetail,
	resetUpdate,
	resetDependent,
	resetAdd,
	resetDelete,
	resetImageDelete,
} = employeeSlice.actions;

export default employeeSlice.reducer;
